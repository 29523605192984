import { AggregationType, Deal, Property, PropertyFields } from "@elphi/types";
import { NavigationPath } from "../../../shared/types/navigation.types";
import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import { propertySpecFields } from "../../form-builder/field-specs/property/property.fields";
import { buildInputs } from "../../form-builder/formBuilder.utils";
import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../utils/validationUtils";
import {
  PSABuyerBorrowerMismatchFieldPath,
  PSAExpirationDateFieldPath,
  allocatedLoanAmountFieldPath,
  annualCapitalExpenditureFieldPath,
  annualHomeownersAssociationFeeAmountFieldPath,
  annualTaxAmountFieldPath,
  appraisalCompletedDateFieldPath,
  appraisalCoordinatorFieldPath,
  appraisalEffectiveDateFieldPath,
  appraisalExpirationDateFieldPath,
  appraisalForm1007RequiredIndicatorFieldPath,
  appraisalManagementCompanyNameFieldPath,
  appraisalManagementContactPointEmailValueFieldPath,
  appraisalManagementContactPointTelephoneValueFieldPath,
  appraisalManagementRepresentativeFullNameFieldPath,
  appraisalOrderedDateFieldPath,
  appraisalTypeFieldPath,
  appraisalValueDerivationTypeFieldPath,
  borrowerRenovationCostsAfterPurchaseAmountFieldPath,
  bridgeLTVPercentFieldPath,
  cemaRefinanceIndicatorFieldPath,
  conditionRatingTypeFieldPath,
  condoCertificateCommentsFieldPath,
  condoCertificateFeesCollectedIndicatorFieldPath,
  condoWarrantableIndicatorFieldPath,
  constructionBudgetAssessmentFieldPath,
  constructionCostAmountFieldPath,
  constructionCostCommentsFieldPath,
  contractClosingDateFieldPath,
  contractExecutionDateFieldPath,
  defaultInterestPaymentsOrFeesNotedIndicatorFieldPath,
  financedUnitCountFieldPath,
  floodInsuranceAddressLineTextFieldPath,
  floodInsuranceAddressUnitIdentifierFieldPath,
  floodInsuranceCityNameFieldPath,
  floodInsuranceContactPointEmailValueFieldPath,
  floodInsuranceContactPointTelephoneValueFieldPath,
  floodInsuranceDeductibleAmountFieldPath,
  floodInsuranceDwellingCoverageAmountFieldPath,
  floodInsuranceFullNameFieldPath,
  floodInsuranceNextPaymentDueDateFieldPath,
  floodInsurancePolicyExpirationDateFieldPath,
  floodInsurancePolicyNumberFieldPath,
  floodInsurancePostalCodeFieldPath,
  floodInsurancePremiumAmountFieldPath,
  floodInsurancePremiumOutstandingAmountFieldPath,
  floodInsurancePremiumPaymentTypeFieldPath,
  floodInsuranceRepresentativeFullNameFieldPath,
  floodInsuranceStateCodeFieldPath,
  internalL1CRefinanceFieldPath,
  internalValuationEligibilityIndicatorFieldPath,
  leaseStatusTypeFieldPath,
  leaseStrategyFieldPath,
  legalDescriptionFieldPath,
  ltcRatePercentFieldPath,
  mortgageeContactFullNameFieldPath,
  mortgageeContactPointEmailValueFieldPath,
  mortgageeContactPointTelephoneFieldPath,
  mortgageeFullNameFieldPath,
  originalPurchaseDateFieldPath,
  originalPurchasePriceAmountFieldPath,
  outstandingLoanPayoffAmountFieldPath,
  parcelNumberFieldPath,
  payoffExpirationDateFieldPath,
  pricingEngineLTARVRatePercentFieldPath,
  propertyAccessInformationFieldPath,
  propertyAddressLineTextFieldPath,
  propertyAddressUnitIdentifierFieldPath,
  propertyAreaValueFieldPath,
  propertyBlockIdentifierFieldPath,
  propertyCityNameFieldPath,
  propertyCountyNameFieldPath,
  propertyDetailCommentsFieldPath,
  propertyInsuranceAddressLineTextFieldPath,
  propertyInsuranceAddressUnitIdentifierFieldPath,
  propertyInsuranceAgentFullNameFieldPath,
  propertyInsuranceBuilderRiskInsuranceFieldPath,
  propertyInsuranceCityNameFieldPath,
  propertyInsuranceContactPointEmailFieldPath,
  propertyInsuranceContactPointTelephoneFieldPath,
  propertyInsuranceDeductibleAmountFieldPath,
  propertyInsuranceDwellingCoverageAmountFieldPath,
  propertyInsuranceFullNameFieldPath,
  propertyInsuranceLiabilityCoverageAmountFieldPath,
  propertyInsuranceNextPaymentDueDateFieldPath,
  propertyInsurancePolicyExpirationDateFieldPath,
  propertyInsurancePolicyIdentifierFieldPath,
  propertyInsurancePostalCodeFieldPath,
  propertyInsurancePremiumAmountFieldPath,
  propertyInsurancePremiumOutstandingAmountFieldPath,
  propertyInsurancePremiumPaymentTypeFieldPath,
  propertyInsuranceRentalLossCoverageAmountFieldPath,
  propertyInsuranceReplacementCoverageIndicatorFieldPath,
  propertyInsuranceStateCodeFieldPath,
  propertyLoanPurposeFieldPath,
  propertyLotIdentifierFieldPath,
  propertyPostalCodeFieldPath,
  propertyRightsOwnershipTypeFieldPath,
  propertyStateCodeFieldPath,
  propertyStateNameFieldPath,
  propertyStructureBuiltYearFieldPath,
  propertySubdivisionIdentifierFieldPath,
  propertyTypeFieldPath,
  propertyValuationAmountFieldPath,
  propertyZoningComplianceRatingTypeFieldPath,
  propertyZoningDescriptionFieldPath,
  psaAddendumIndicatorFieldPath,
  psaAssignmentContractIndicatorFieldPath,
  psaExtensionFormIndicatorFieldPath,
  psaExtensionRequestedIndicatorFieldPath,
  psaSellerOwnerRecordMismatchIndicatorFieldPath,
  purchasePriceAmountFieldPath,
  qualityRatingTypeFieldPath,
  rentalLTVPercentFieldPath,
  specificZoningClassificationFieldPath,
  subjectToPropertyValuationAmountFieldPath,
  totalBathroomCountFieldPath,
  totalBedroomCountFieldPath,
  totalCostAmountFieldPath
} from "../field-paths/property.fieldPaths";
import {
  hideBridgePlus,
  hideFixNFlip,
  hideFixNFlipNoBridge,
  hideRental30,
  hideRental30OrEmpty,
  isEmptyLoanProgramType,
  isFixNFlip,
  isNewConstruction,
  isRental30Premier
} from "./sections.utils";
import { serviceLinkFloodVerifySection } from "./service-link/serviceLinkFloodVerifySection";

export const createPropertySection = (state: PropertyFields): Section => {
  return {
    header: "Create New",
    inputs: buildInputs<Partial<Property>>({
      state,
      onChange: undefined,
      fieldSpecs: propertySpecFields,
      hideAttachedComponent: true,
      specs: [
        {
          path: propertyAddressLineTextFieldPath
        },
        {
          path: propertyAddressUnitIdentifierFieldPath
        },
        {
          path: propertyCityNameFieldPath,
          validation: stringValidation
        },
        {
          path: propertyStateNameFieldPath,
          validation: singleSelectValidation
        },
        {
          path: propertyStateCodeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: propertyPostalCodeFieldPath
        },
        {
          path: propertyLotIdentifierFieldPath
        },
        {
          path: propertyBlockIdentifierFieldPath
        },
        {
          path: propertySubdivisionIdentifierFieldPath
        }
      ]
    })
  };
};

export const propertyDescriptionSection = (
  state: Partial<PropertyFields>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Property Description",
    inputs: buildInputs<Partial<Property>>({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          path: propertyAddressLineTextFieldPath,
          validation: stringValidation
        },
        {
          path: propertyAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        { path: propertyCityNameFieldPath, validation: stringValidation },
        { path: propertyCountyNameFieldPath, validation: stringValidation },
        {
          path: propertyStateNameFieldPath,
          validation: singleSelectValidation
        },
        {
          path: propertyStateCodeFieldPath,
          validation: singleSelectValidation
        },
        { path: propertyPostalCodeFieldPath, validation: numberValidation },
        { path: propertyTypeFieldPath, validation: singleSelectValidation },
        { path: financedUnitCountFieldPath, validation: numberValidation },
        { path: parcelNumberFieldPath, validation: stringValidation },
        { path: legalDescriptionFieldPath, validation: stringValidation },
        { path: propertyLotIdentifierFieldPath, validation: stringValidation },
        {
          path: propertyBlockIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: propertySubdivisionIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: propertyRightsOwnershipTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: condoWarrantableIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: condoCertificateFeesCollectedIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: condoCertificateCommentsFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};

export const propertyDetailSection = (
  state: Partial<PropertyFields>,
  dealState?: Deal,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Property Detail",
    inputs: buildInputs<Partial<Property>>({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          path: propertyLoanPurposeFieldPath,
          validation: singleSelectValidation
        },
        { path: purchasePriceAmountFieldPath, validation: moneyValidation },
        {
          path: outstandingLoanPayoffAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: defaultInterestPaymentsOrFeesNotedIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.AllocatedLoanAmount?.focused !== "override",
          path: allocatedLoanAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.aggregations?.BridgeLTVPercent?.focused !== "override",
          path: bridgeLTVPercentFieldPath,
          validation: percentValidation,
          isHidden: hideBridgePlus(dealState)
        },
        {
          path: rentalLTVPercentFieldPath,
          validation: percentValidation,
          isHidden: hideRental30(dealState)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.PricingEngineLTARVRatePercent?.focused !==
            "override",

          path: pricingEngineLTARVRatePercentFieldPath,
          validation: percentValidation,
          isHidden: hideFixNFlipNoBridge(dealState)
        },
        {
          path: constructionCostAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(dealState)
        },
        {
          path: constructionCostCommentsFieldPath,
          validation: stringValidation,
          isHidden: hideFixNFlip(dealState)
        },
        {
          path: leaseStatusTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(dealState)
        },
        { path: originalPurchaseDateFieldPath, validation: dateValidation },
        {
          path: originalPurchasePriceAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: borrowerRenovationCostsAfterPurchaseAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.LTCRatePercent?.focused !== "override",
          path: ltcRatePercentFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: annualTaxAmountFieldPath,
          validation: moneyValidation,
          isReadOnly:
            state?.aggregations?.AnnualTaxAmount?.focused !== "override",
          isHidden: hideRental30(dealState)
        },
        {
          path: annualHomeownersAssociationFeeAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(dealState)
        },
        {
          path: annualCapitalExpenditureFieldPath,
          validation: moneyValidation,
          isHidden:
            !isRental30Premier(dealState) && !isEmptyLoanProgramType(dealState)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.TotalCostAmount?.focused !== "override",

          path: totalCostAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: propertyDetailCommentsFieldPath,
          validation: stringValidation
        },
        {
          path: internalL1CRefinanceFieldPath,
          validation: booleanValidation
        },
        {
          path: leaseStrategyFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(dealState)
        },
        {
          path: constructionBudgetAssessmentFieldPath,
          validation: singleSelectValidation,
          isHidden:
            !isNewConstruction(dealState) &&
            !isFixNFlip(dealState) &&
            !isEmptyLoanProgramType(dealState)
        }
      ]
    })
  };
};

export const purchaseAndSaleAgreementSection = (
  state: Partial<PropertyFields>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Purchase and Sale Agreement",
    inputs: buildInputs<Partial<Property>>({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        { path: contractExecutionDateFieldPath, validation: dateValidation },
        { path: contractClosingDateFieldPath, validation: dateValidation },
        { path: PSAExpirationDateFieldPath, validation: dateValidation },
        {
          path: psaExtensionRequestedIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: psaExtensionFormIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: psaAssignmentContractIndicatorFieldPath,
          validation: booleanValidation
        },
        { path: psaAddendumIndicatorFieldPath, validation: booleanValidation },
        {
          path: psaSellerOwnerRecordMismatchIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: PSABuyerBorrowerMismatchFieldPath,
          validation: booleanValidation
        }
      ]
    })
  };
};

export const payoffSection = (
  state: Partial<PropertyFields>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Payoff",
    inputs: buildInputs<Partial<Property>>({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        { path: payoffExpirationDateFieldPath, validation: dateValidation },
        { path: mortgageeFullNameFieldPath, validation: stringValidation },
        {
          path: mortgageeContactFullNameFieldPath,
          validation: stringValidation
        },
        {
          path: mortgageeContactPointTelephoneFieldPath,
          validation: stringValidation
        },
        {
          path: mortgageeContactPointEmailValueFieldPath,
          validation: emailValidation
        },
        {
          path: cemaRefinanceIndicatorFieldPath,
          validation: booleanValidation
        }
      ]
    })
  };
};

export const valuationSection = (
  state: Partial<PropertyFields>,
  dealState: Deal | undefined,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Valuation",
    inputs: buildInputs<Partial<Property>>({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          path: appraisalValueDerivationTypeFieldPath,
          validation: singleSelectValidation
        },
        { path: propertyValuationAmountFieldPath, validation: moneyValidation },
        {
          path: subjectToPropertyValuationAmountFieldPath,
          validation: moneyValidation,
          isHidden: !hideRental30OrEmpty(dealState)
        },
        { path: appraisalOrderedDateFieldPath, validation: dateValidation },
        { path: appraisalCompletedDateFieldPath, validation: dateValidation },
        { path: appraisalEffectiveDateFieldPath, validation: dateValidation },
        { path: appraisalExpirationDateFieldPath, validation: dateValidation },
        {
          path: appraisalTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: qualityRatingTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: conditionRatingTypeFieldPath,
          validation: singleSelectValidation
        },
        { path: propertyAreaValueFieldPath, validation: numberValidation },
        { path: totalBedroomCountFieldPath, validation: numberValidation },
        { path: totalBathroomCountFieldPath, validation: numberValidation },
        {
          path: propertyStructureBuiltYearFieldPath,
          validation: numberValidation
        },
        { path: propertyCountyNameFieldPath, validation: stringValidation },
        {
          path: specificZoningClassificationFieldPath,
          validation: stringValidation
        },
        {
          path: propertyZoningDescriptionFieldPath,
          validation: stringValidation
        },
        {
          path: propertyZoningComplianceRatingTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: appraisalCoordinatorFieldPath,
          validation: singleSelectValidation
        },
        {
          path: internalValuationEligibilityIndicatorFieldPath,
          validation: booleanValidation,
          isHidden: hideFixNFlip(dealState)
        },
        {
          path: appraisalForm1007RequiredIndicatorFieldPath,
          validation: booleanValidation,
          isHidden: hideRental30(dealState)
        },
        {
          path: appraisalManagementRepresentativeFullNameFieldPath,
          validation: stringValidation
        },

        {
          path: appraisalManagementContactPointTelephoneValueFieldPath,
          validation: stringValidation
        },
        {
          path: appraisalManagementContactPointEmailValueFieldPath,
          validation: emailValidation
        },
        {
          path: appraisalManagementCompanyNameFieldPath,
          validation: stringValidation
        },
        {
          path: propertyAccessInformationFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};

export const floodCertificationSection = (
  state: Partial<PropertyFields>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    ...serviceLinkFloodVerifySection(state, onChange),
    header: "Flood Certification"
  };
};

export const floodInsuranceSection = (
  state: Partial<PropertyFields>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Flood Insurance",
    inputs: buildInputs<Partial<Property>>({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          path: floodInsuranceDwellingCoverageAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: floodInsurancePremiumAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: floodInsurancePremiumPaymentTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: floodInsurancePremiumOutstandingAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: floodInsurancePolicyNumberFieldPath,
          validation: stringValidation
        },
        {
          path: floodInsurancePolicyExpirationDateFieldPath,
          validation: dateValidation
        },
        {
          path: floodInsuranceNextPaymentDueDateFieldPath,
          validation: dateValidation
        },
        {
          path: floodInsuranceDeductibleAmountFieldPath,
          validation: moneyValidation
        },
        { path: floodInsuranceFullNameFieldPath, validation: stringValidation },
        {
          path: floodInsuranceRepresentativeFullNameFieldPath,
          validation: stringValidation
        },
        {
          path: floodInsuranceContactPointTelephoneValueFieldPath,
          validation: stringValidation
        },
        {
          path: floodInsuranceContactPointEmailValueFieldPath,
          validation: emailValidation
        },
        {
          path: floodInsuranceAddressLineTextFieldPath,
          validation: stringValidation
        },
        {
          path: floodInsuranceAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        { path: floodInsuranceCityNameFieldPath, validation: stringValidation },
        {
          path: floodInsuranceStateCodeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: floodInsurancePostalCodeFieldPath,
          validation: numberValidation
        }
      ]
    })
  };
};
export const propertyInsuranceSection = (
  state: Partial<PropertyFields>,
  dealState: Deal | undefined,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Property Insurance",
    inputs: buildInputs<Partial<Property>>({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          path: propertyInsuranceDwellingCoverageAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: propertyInsuranceLiabilityCoverageAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: propertyInsuranceReplacementCoverageIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: propertyInsuranceRentalLossCoverageAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(dealState)
        },
        {
          path: propertyInsurancePremiumOutstandingAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: propertyInsurancePremiumAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: propertyInsurancePremiumPaymentTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: propertyInsurancePolicyIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: propertyInsurancePolicyExpirationDateFieldPath,
          validation: dateValidation
        },
        {
          path: propertyInsuranceNextPaymentDueDateFieldPath,
          validation: dateValidation
        },
        {
          path: propertyInsuranceDeductibleAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: propertyInsuranceAgentFullNameFieldPath,
          validation: stringValidation
        },
        {
          path: propertyInsuranceFullNameFieldPath,
          validation: stringValidation
        },
        {
          path: propertyInsuranceContactPointTelephoneFieldPath,
          validation: stringValidation
        },
        {
          path: propertyInsuranceContactPointEmailFieldPath,
          validation: emailValidation
        },
        {
          path: propertyInsuranceAddressLineTextFieldPath,
          validation: stringValidation
        },
        {
          path: propertyInsuranceAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: propertyInsuranceCityNameFieldPath,
          validation: stringValidation
        },
        {
          path: propertyInsuranceStateCodeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: propertyInsurancePostalCodeFieldPath,
          validation: numberValidation
        },
        {
          path: propertyInsuranceBuilderRiskInsuranceFieldPath,
          validation: moneyValidation
        }
      ]
    })
  };
};

export const sectionsBeforeTables = (r: {
  state: Partial<PropertyFields>;
  dealState?: Deal;
  selectedPropertyId: string | undefined;
  onChange: (v: OnChangeInput) => void;
}) => {
  const { state, dealState, onChange } = r;

  return [
    propertyDescriptionSection(state, onChange),
    propertyDetailSection(state, dealState, onChange),
    purchaseAndSaleAgreementSection(state, onChange),
    payoffSection(state, onChange),
    valuationSection(state, dealState, onChange)
  ];
};

export const sectionsAfterTables = (r: {
  state: Partial<PropertyFields>;
  dealState?: Deal;
  selectedPropertyId: string | undefined;
  onChange: (v: OnChangeInput) => void;
  navigationPath?: NavigationPath;
}) => {
  const { state, dealState, onChange } = r;

  return [
    floodCertificationSection(state, onChange),
    floodInsuranceSection(state, onChange),
    propertyInsuranceSection(state, dealState, onChange)
  ];
};
