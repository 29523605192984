import { TObject, TProperties, Type } from "@sinclair/typebox";
import {
  AppraisalCoordinatorType,
  AppraisalType,
  AppraisalValueDerivationType,
  BillingFrequencyType,
  BooleanSelectType,
  ConditionRatingType,
  ConstructionBudgetAssessmentType,
  FloodCertificateVendorType,
  FloodCertificationActionType,
  FloodCheckStatus,
  FloodCheckStatusShortType,
  FloodInsurancePremiumPaymentType,
  FloodInsuranceRequiredStatusType,
  FloodZoneDesignationType,
  InspectionInitialDelayReasonStatusType,
  InspectionOrderType,
  IntegrationType,
  LeaseStatusType,
  LeaseStrategyType,
  PortfolioTemplateType,
  Property,
  PropertyInsurancePremiumPaymentType,
  PropertyLoanPurpose,
  PropertyReportVendorType,
  PropertyRightsOwnershipType,
  PropertyType,
  PropertyZoningComplianceRatingType,
  QualityRatingType,
  RentalDataVendorType,
  ServiceLinkOrderStatusShortType,
  getPropertyTemplate
} from "../entities";
import {
  AggregationFieldSchema,
  ThirdPartyFieldSchema
} from "./aggregation.typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { FieldExceptionTemplateSchema } from "./fieldExceptions.typebox";
import { BaseAddressSchema } from "./location.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import {
  AllowEmptyString,
  ArrayField,
  BooleanField,
  DateField,
  DecimalField,
  Deprecated,
  EmailField,
  EnumField,
  IntegerField,
  MoneyAmountField,
  NumberField,
  PercentField,
  PhoneNumberField,
  StringField,
  UnlimitedDecimalPercentField
} from "./utils.typebox";
import { FieldMetaTarget, buildFieldMetadata } from "./utils/fieldMeta.utils";

export const PropertyAddressFieldsSchema = Type.Object({
  PropertyIdentifier: StringField,
  LegalDescription: StringField,
  FullAddressName: StringField,
  PropertyLotIdentifier: StringField,
  PropertyBlockIdentifier: StringField,
  PropertySubdivisionIdentifier: StringField
});

export const PropertyAddressSchema = Type.Intersect([
  BaseAddressSchema,
  PropertyAddressFieldsSchema
]);

export const AppraisalFieldsSchema = Type.Object({
  AppraisalValueDerivationType: EnumField(AppraisalValueDerivationType),
  AppraisalOrderedDate: DateField,
  AppraisalCompletedDate: DateField,
  AppraisalEffectiveDate: DateField,
  AppraisalExpirationDate: DateField,
  AppraisalType: EnumField(AppraisalType),
  QualityRatingType: EnumField(QualityRatingType),
  ConditionRatingType: EnumField(ConditionRatingType),
  TotalBedroomCount: DecimalField,
  TotalBathroomCount: DecimalField,
  PropertyStructureBuiltYear: IntegerField,
  AppraisalDate: DateField,
  PropertyAreaValue: NumberField,
  PropertyBuiltYear: IntegerField,
  SpecificZoningClassification: StringField,
  PropertyZoningDescription: StringField,
  PropertyZoningComplianceRatingType: EnumField(
    PropertyZoningComplianceRatingType
  ),
  PropertyValuationAmount: MoneyAmountField,
  SubjectToPropertyValuationAmount: MoneyAmountField,
  AppraisalCoordinator: EnumField(AppraisalCoordinatorType),
  InternalValuationEligibilityIndicator: BooleanField,
  PropertyAccessInformation: StringField,
  AppraisalForm1007RequiredIndicator: BooleanField
});

export const AppraisalManagementCompanySchema = Type.Object({
  RepresentativeFullName: StringField,
  ContactPointTelephoneValue: PhoneNumberField,
  ContactPointEmailValue: EmailField,
  AppraisalManagementCompanyName: StringField
});

export const FloodCertificationFieldsSchema = Type.Object({
  FloodCertificationActionType: EnumField(FloodCertificationActionType),
  FloodInsuranceRequiredStatusType: EnumField(FloodInsuranceRequiredStatusType),
  CommunityNumber: StringField,
  FloodCertificationIdentifier: StringField,
  MapPanelIdentifier: StringField,
  MapDate: DateField,
  OriginalDeterminationDate: DateField,
  FloodZoneDesignationType: EnumField(FloodZoneDesignationType),
  SpecialFloodHazardAreaIndicator: BooleanField,
  LifeOfLoanIndicator: BooleanField,
  FloodCheckIdentifier: StringField,
  FloodProductCertifyDate: DateField,
  FloodCheckDescription: StringField,
  FloodCheckInformation: StringField,
  FileDate: DateField,
  FileTime: StringField,
  UserID: StringField,
  Password: StringField,
  OrderDate: DateField,
  OrderTime: StringField,
  CustomerNumber: StringField,
  ProductCode: StringField,
  QuickCheckOrderDate: DateField,
  LOLFlagIndicator: BooleanField,
  CTFlagIndicator: BooleanField,
  FloodFlagIndicator: BooleanField,
  AcceptedAcknowledgmentIndicator: BooleanField,
  UsersEmailAddress: StringField
});

export const FloodInsuranceAgencyFieldsSchema = Type.Object({
  FullName: StringField,
  RepresentativeFullName: StringField,
  ContactPointTelephoneValue: PhoneNumberField,
  ContactPointEmailValue: EmailField
});

export const FloodInsuranceAgencySchema = Type.Intersect([
  BaseAddressSchema,
  FloodInsuranceAgencyFieldsSchema
]);

export const FloodInsuranceFieldsSchema = Type.Object({
  DwellingCoverageAmount: MoneyAmountField,
  InsurancePremiumPaymentType: EnumField(FloodInsurancePremiumPaymentType),
  InsurancePremiumAmount: MoneyAmountField,
  InsurancePremiumOutstandingAmount: MoneyAmountField,
  DeductibleAmount: MoneyAmountField,
  PolicyNumber: StringField,
  PolicyExpirationDate: DateField,
  NextPaymentDueDate: DateField
});

export const PropertyInsuranceAgencyFieldsSchema = Type.Object({
  FullName: StringField,
  InsuranceAgentFullName: StringField,
  ContactPointTelephoneValue: PhoneNumberField,
  ContactPointEmailValue: EmailField
});

export const PropertyInsuranceAgencySchema = Type.Intersect([
  BaseAddressSchema,
  PropertyInsuranceAgencyFieldsSchema
]);

export const PropertyInsuranceFieldsSchema = Type.Object({
  PropertyInsurancePolicyIdentifier: StringField,
  DeductibleAmount: MoneyAmountField,
  DwellingCoverageAmount: MoneyAmountField,
  RentalLossCoverageAmount: MoneyAmountField,
  LiabilityCoverageAmount: MoneyAmountField,
  InsurancePremiumAmount: MoneyAmountField,
  InsurancePremiumOutstandingAmount: MoneyAmountField,
  PolicyExpirationDate: DateField,
  NextPaymentDueDate: DateField,
  AnnualPropertyInsuranceAmount: MoneyAmountField,
  ReplacementCoverageIndicator: BooleanField,
  InsurancePremiumPaymentType: EnumField(PropertyInsurancePremiumPaymentType),
  BuilderRiskInsurance: StringField
});

export const SheetMetadataSchema = Type.Object({
  type: EnumField(PortfolioTemplateType)
});

export const PropertyExceptionsSchema = Type.Object({
  Appraisal: Deprecated(
    Type.Object({
      AppraisalType: Deprecated(Type.Partial(FieldExceptionTemplateSchema))
    })
  ),
  LTVLoanAmount: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  AdjustedRentAmount: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  CapitalExpenditureAmount: Deprecated(
    Type.Partial(FieldExceptionTemplateSchema)
  ),
  PricingEngineLTARVRatePercent: Deprecated(
    Type.Partial(FieldExceptionTemplateSchema)
  ),
  BlendedLTCRatePercent: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  BlendedLTCLoanAmount: Deprecated(Type.Partial(FieldExceptionTemplateSchema)),
  ConstructionLTCRatePercent: Deprecated(
    Type.Partial(FieldExceptionTemplateSchema)
  ),
  MaxConstructionBudgetLTCPercent: Deprecated(
    Type.Partial(FieldExceptionTemplateSchema)
  )
});

export const AggregationsLeasedFinancedUnitTemplateSchema = Type.Object({
  AdjustedRentAmount: AggregationFieldSchema(MoneyAmountField)
});

export const AggregationsLeasedFinancedUnitsSchema = Type.Object({
  unit0: Type.Partial(AggregationsLeasedFinancedUnitTemplateSchema),
  unit1: Type.Partial(AggregationsLeasedFinancedUnitTemplateSchema),
  unit2: Type.Partial(AggregationsLeasedFinancedUnitTemplateSchema),
  unit3: Type.Partial(AggregationsLeasedFinancedUnitTemplateSchema),
  unit4: Type.Partial(AggregationsLeasedFinancedUnitTemplateSchema),
  unit5: Type.Partial(AggregationsLeasedFinancedUnitTemplateSchema),
  unit6: Type.Partial(AggregationsLeasedFinancedUnitTemplateSchema),
  unit7: Type.Partial(AggregationsLeasedFinancedUnitTemplateSchema),
  unit8: Type.Partial(AggregationsLeasedFinancedUnitTemplateSchema)
});

export const LeasedFinancedUnitTemplateSchema = Type.Object({
  UnitName: StringField,
  MonthlyLeaseRentAmount: MoneyAmountField,
  MonthlyMarketRentAmount: MoneyAmountField,
  LeaseStatusType: EnumField(LeaseStatusType),
  LeaseExpirationDate: DateField,
  RentReportConfidenceScore: NumberField,
  PaymentVerificationIndicator: BooleanField,
  CorporateLeaseIndicator: BooleanField
});

export const LeasedFinancedUnitsSchema = Type.Object({
  unit0: Type.Partial(LeasedFinancedUnitTemplateSchema),
  unit1: Type.Partial(LeasedFinancedUnitTemplateSchema),
  unit2: Type.Partial(LeasedFinancedUnitTemplateSchema),
  unit3: Type.Partial(LeasedFinancedUnitTemplateSchema),
  unit4: Type.Partial(LeasedFinancedUnitTemplateSchema),
  unit5: Type.Partial(LeasedFinancedUnitTemplateSchema),
  unit6: Type.Partial(LeasedFinancedUnitTemplateSchema),
  unit7: Type.Partial(LeasedFinancedUnitTemplateSchema),
  unit8: Type.Partial(LeasedFinancedUnitTemplateSchema)
});

export const PropertyInspectionFieldsSchema = Type.Object({
  InspectionInitialRequestDate: DateField,
  InspectionRequestOrderedDate: DateField,
  InspectionReportDueDate: DateField,
  InspectionOrderType: EnumField(InspectionOrderType),
  InspectorFullName: StringField,
  ConstructionAnalystFullName: StringField,
  InspectionOrderReceivedDate: DateField,
  InspectionOrderProcessedDate: DateField,
  InspectionDocumentsReceivedDate: DateField,
  InspectionInitialDelayReasonStatusType: ArrayField(
    EnumField(InspectionInitialDelayReasonStatusType)
  )
});

const fieldMetaSchema = buildFieldMetadata<Property, TObject<TProperties>>({
  entityTemplate: getPropertyTemplate(),
  target: FieldMetaTarget.Schema
});

export const PropertyFieldsSchema = Type.Object({
  FieldMeta: Type.Partial(fieldMetaSchema),
  aggregations: Type.Partial(
    Type.Object({
      CityInitialTaxEscrowMonthCount: AggregationFieldSchema(IntegerField),
      CityInitialTaxEscrowAmount: AggregationFieldSchema(MoneyAmountField),
      CountyInitialTaxEscrowMonthCount: AggregationFieldSchema(IntegerField),
      CountyInitialTaxEscrowAmount: AggregationFieldSchema(MoneyAmountField),
      InitialPropertyInsuranceEscrowMonthCount:
        AggregationFieldSchema(IntegerField),
      InitialPropertyInsuranceEscrowAmount:
        AggregationFieldSchema(MoneyAmountField),
      InitialFloodInsuranceEscrowAmount:
        AggregationFieldSchema(MoneyAmountField),
      InitialFloodInsuranceEscrowMonthCount:
        AggregationFieldSchema(IntegerField),
      SpecialAssessmentInitialTaxEscrowAmount:
        AggregationFieldSchema(MoneyAmountField),
      SpecialAssessmentInitialTaxEscrowMonthCount:
        AggregationFieldSchema(IntegerField),
      MonthlyLeaseRentAmount: AggregationFieldSchema(MoneyAmountField),
      TotalInsuranceEscrowAmount: AggregationFieldSchema(MoneyAmountField),
      TotalTaxEscrowAmount: AggregationFieldSchema(MoneyAmountField),
      MonthlyMarketRentAmount: AggregationFieldSchema(MoneyAmountField),
      AdjustedRentAmount: AggregationFieldSchema(MoneyAmountField),
      AnnualTaxAmount: AggregationFieldSchema(MoneyAmountField),
      BridgeLTVPercent: ThirdPartyFieldSchema(UnlimitedDecimalPercentField),
      AllocatedLoanAmount: AggregationFieldSchema(MoneyAmountField),
      TotalCostAmount: AggregationFieldSchema(MoneyAmountField),
      LTCRatePercent: AggregationFieldSchema(PercentField),
      MinimumRentLossCoverageAmount: AggregationFieldSchema(MoneyAmountField),
      Integrations: Type.Partial(
        Type.Object({
          [IntegrationType.PropertyReport]: Type.Partial(
            Type.Object({
              [PropertyReportVendorType.SiteX]: Type.Partial(
                Type.Object({
                  addressLineText: ThirdPartyFieldSchema(StringField),
                  addressUnitIdentifier: ThirdPartyFieldSchema(StringField),
                  cityName: ThirdPartyFieldSchema(StringField),
                  stateCode: ThirdPartyFieldSchema(StringField),
                  postalCode: ThirdPartyFieldSchema(StringField),
                  countyName: ThirdPartyFieldSchema(StringField),
                  assessorParcelNumber: ThirdPartyFieldSchema(StringField),
                  fips: ThirdPartyFieldSchema(StringField),
                  orderStatus: ThirdPartyFieldSchema(StringField),
                  orderCompletedOn: ThirdPartyFieldSchema(StringField)
                })
              )
            })
          ),
          [IntegrationType.FloodCertificate]: Type.Partial(
            Type.Object({
              [FloodCertificateVendorType.ServiceLink]: Type.Partial(
                Type.Object({
                  OrderNumber: ThirdPartyFieldSchema(StringField),
                  OrderStatus: ThirdPartyFieldSchema(
                    EnumField(ServiceLinkOrderStatusShortType)
                  ),
                  FloodCheckStatus: ThirdPartyFieldSchema(
                    EnumField(FloodCheckStatusShortType)
                  ),
                  FloodCheckInfo: ThirdPartyFieldSchema(StringField),
                  FloodCheckDisclaimer: ThirdPartyFieldSchema(StringField),
                  FloodCheckStatusDescription:
                    ThirdPartyFieldSchema(StringField),
                  Disclaimer: ThirdPartyFieldSchema(StringField),
                  DeterminationDate: ThirdPartyFieldSchema(StringField),
                  FloodMapDate: ThirdPartyFieldSchema(StringField),
                  FloodZone: ThirdPartyFieldSchema(StringField),
                  GeoCity: ThirdPartyFieldSchema(StringField),
                  GeoCounty: ThirdPartyFieldSchema(StringField),
                  GeoPropertyAddress1: ThirdPartyFieldSchema(StringField),
                  GeoStateCode: ThirdPartyFieldSchema(StringField),
                  GeoZipFirst5: ThirdPartyFieldSchema(StringField),
                  GeoZipLast4: ThirdPartyFieldSchema(StringField),
                  NFIPLegalCommunityNumber: ThirdPartyFieldSchema(StringField),
                  NFIPLegalCommunityName: ThirdPartyFieldSchema(StringField),
                  NFIPMapCommunityName: ThirdPartyFieldSchema(StringField),
                  NFIPMapCommunityNumber: ThirdPartyFieldSchema(StringField),
                  NFIPMapPanelNumber: ThirdPartyFieldSchema(StringField),
                  NFIPPrgStat: ThirdPartyFieldSchema(StringField),
                  PropertyMSAMD: ThirdPartyFieldSchema(StringField),
                  RequestedInformation: ThirdPartyFieldSchema(StringField),
                  ResultCode: ThirdPartyFieldSchema(StringField),
                  ResultDescription: ThirdPartyFieldSchema(StringField),
                  ReturnTime: ThirdPartyFieldSchema(StringField),
                  ReturnDate: ThirdPartyFieldSchema(StringField),
                  TSDNComments: ThirdPartyFieldSchema(StringField),
                  SpecialFloodHazardAreaIndicator: AggregationFieldSchema(
                    EnumField(BooleanSelectType)
                  )
                })
              )
            })
          ),
          [IntegrationType.RentalData]: Type.Partial(
            Type.Object({
              [RentalDataVendorType.RentRange]: Type.Partial(
                Type.Object({
                  confidenceScore: ThirdPartyFieldSchema(StringField),
                  errorMessage: ThirdPartyFieldSchema(StringField),
                  orderStatus: ThirdPartyFieldSchema(StringField),
                  requestId: ThirdPartyFieldSchema(StringField)
                })
              )
            })
          )
        })
      ),
      FloodCertification: Deprecated(
        Type.Partial(
          Type.Object({
            OrderNumber: ThirdPartyFieldSchema(StringField),
            ReturnDate: ThirdPartyFieldSchema(DateField),
            ReturnTime: ThirdPartyFieldSchema(StringField),
            FloodCheckStatus: ThirdPartyFieldSchema(
              EnumField(FloodCheckStatus)
            ),
            StatusDescription: ThirdPartyFieldSchema(StringField),
            FloodCheckInfo: ThirdPartyFieldSchema(StringField),
            Disclaimer: ThirdPartyFieldSchema(StringField),
            // Full
            OrderStatus: ThirdPartyFieldSchema(
              EnumField(ServiceLinkOrderStatusShortType)
            ),
            NFIPMapCommunityNumber: ThirdPartyFieldSchema(StringField),
            NFIPMapCommunityName: ThirdPartyFieldSchema(StringField),
            NFIPLegalCommunityNumber: ThirdPartyFieldSchema(StringField),
            NFIPLegalCommunityName: ThirdPartyFieldSchema(StringField),
            NFIPMapPanelNumber: ThirdPartyFieldSchema(StringField),
            NFIPPrgStat: ThirdPartyFieldSchema(StringField),
            DeterminationDate: ThirdPartyFieldSchema(DateField),
            FloodMapDate: ThirdPartyFieldSchema(DateField),
            FloodZone: ThirdPartyFieldSchema(StringField),
            GeoPropertyAddress1: ThirdPartyFieldSchema(StringField),
            GeoCity: ThirdPartyFieldSchema(StringField),
            GeoStateCode: ThirdPartyFieldSchema(StringField),
            GeoZipLast4: ThirdPartyFieldSchema(StringField),
            GeoZipFirst5: ThirdPartyFieldSchema(StringField),
            GeoCounty: ThirdPartyFieldSchema(StringField),
            PropertyMSAMD: ThirdPartyFieldSchema(StringField),
            TSDNComments: ThirdPartyFieldSchema(StringField),
            RequestedInformation: ThirdPartyFieldSchema(StringField),
            // Error Response
            ResultDescription: ThirdPartyFieldSchema(StringField),
            ResultCode: ThirdPartyFieldSchema(StringField)
          })
        )
      ),
      LeasedFinancedUnits: Type.Partial(AggregationsLeasedFinancedUnitsSchema)
    })
  ),
  Address: Type.Partial(PropertyAddressSchema),
  Appraisal: Type.Partial(AppraisalFieldsSchema),
  FloodCertification: Deprecated(Type.Partial(FloodCertificationFieldsSchema)),
  FloodInsuranceAgency: Type.Partial(FloodInsuranceAgencySchema),
  FloodInsurance: Type.Partial(FloodInsuranceFieldsSchema),
  PropertyInsuranceAgency: Type.Partial(PropertyInsuranceAgencySchema),
  PropertyInsurance: Type.Partial(PropertyInsuranceFieldsSchema),
  PropertyType: EnumField(PropertyType),
  CondoWarrantableIndicator: BooleanField,
  FinancedUnitCount: IntegerField,
  PropertyLoanPurpose: EnumField(PropertyLoanPurpose),
  PurchasePriceAmount: MoneyAmountField,
  ContractClosingDate: DateField,
  ContractExecutionDate: DateField,
  OriginalPurchaseDate: DateField,
  OriginalPurchasePriceAmount: MoneyAmountField,
  OutstandingLoanPayoffAmount: MoneyAmountField,
  LeaseStatusType: EnumField(LeaseStatusType),
  AnnualTaxesAmount: MoneyAmountField,
  AnnualHomeownersAssociationFeeAmount: MoneyAmountField,
  DeferredMaintenanceAmount: MoneyAmountField,
  ParcelNumber: StringField,
  RenovationCostAmount: MoneyAmountField,
  ConstructionCostAmount: MoneyAmountField,
  ConstructionLTCRatePercent: Deprecated(PercentField),
  ConstructionLTCLoanAmount: Deprecated(MoneyAmountField),
  BlendedLTCRatePercent: Deprecated(PercentField),
  BlendedLTCLoanAmount: Deprecated(MoneyAmountField),
  PricingEngineLTARVRatePercent: UnlimitedDecimalPercentField,
  LTARVAmount: MoneyAmountField,
  LTVLoanAmount: Deprecated(MoneyAmountField),
  NetFundAmount: MoneyAmountField,
  NetFundRatePercent: PercentField,
  NetWireAmount: MoneyAmountField,
  AllocatedRDSRRatePercent: PercentField,
  LeasedFinancedUnits: Type.Partial(LeasedFinancedUnitsSchema),
  SheetMetadata: Type.Partial(SheetMetadataSchema),
  Exceptions: Type.Partial(PropertyExceptionsSchema),
  AppraisalManagementCompany: Type.Partial(AppraisalManagementCompanySchema),
  CondoCertificateFeesCollectedIndicator: BooleanField,
  CondoCertificateComments: StringField,
  ConstructionCostComments: StringField,
  PropertyDetailComments: StringField,
  PSASignedIndicator: BooleanField,
  PSAExtensionRequestedIndicator: BooleanField,
  PSAExtensionFormIndicator: BooleanField,
  PSAAssignmentContractIndicator: BooleanField,
  PSAAddendumIndicator: BooleanField,
  PSASellerOwnerRecordMismatchIndicator: BooleanField,
  PSABuyerBorrowerMismatchIndicator: BooleanField,
  PSAExpirationDate: DateField,
  PayoffExpirationDate: DateField,
  MortgageeFullName: StringField,
  MortgageeContactFullName: StringField,
  MortgageeContactPointTelephoneValue: PhoneNumberField,
  MortgageeContactPointEmailValue: EmailField,
  CityAnnualTaxAmount: MoneyAmountField,
  CityTaxDueDate: DateField,
  CityTaxBillingFrequencyType: EnumField(BillingFrequencyType),
  CityTaxingAuthority: StringField,
  CountyAnnualTaxAmount: MoneyAmountField,
  CountyTaxDueDate: DateField,
  CountyTaxBillingFrequencyType: EnumField(BillingFrequencyType),
  CountyTaxingAuthority: StringField,
  SpecialAssessmentAnnualTaxAmount: MoneyAmountField,
  SpecialAssessmentTaxDueDate: DateField,
  SpecialAssessmentTaxBillingFrequencyType: EnumField(BillingFrequencyType),
  SpecialAssessmentTaxingAuthority: StringField,
  PropertyInspection: Type.Partial(PropertyInspectionFieldsSchema),
  CEMARefinanceIndicator: BooleanField,
  PropertyRightsOwnershipType: EnumField(PropertyRightsOwnershipType),
  RentReportConfidenceScorePercent: PercentField,
  TotalRentalIncomeAmount: MoneyAmountField,
  CapitalExpenditureAmount: MoneyAmountField,
  MaxConstructionBudgetLTCPercent: Deprecated(PercentField),
  BorrowerRenovationCostsAfterPurchaseAmount: MoneyAmountField,
  InternalL1CRefinanceIndicator: BooleanField,
  LeaseStrategy: EnumField(LeaseStrategyType),
  RentalLTVPercent: PercentField,
  ConstructionBudgetAssessment: EnumField(ConstructionBudgetAssessmentType),
  AnnualCapitalExpenditure: MoneyAmountField,
  DefaultInterestPaymentsOrFeesNotedIndicator: BooleanField,
  AcceptedAcknowledgmentIndicator: BooleanField,
  UsersEmailAddress: StringField,
  Integrations: Type.Partial(
    Type.Object({
      [IntegrationType.FloodCertificate]: Type.Partial(
        Type.Object({
          [FloodCertificateVendorType.ServiceLink]: Type.Partial(
            Type.Object({
              LOLFlagIndicator: AllowEmptyString(
                Type.Union([Type.Literal("Y")])
              ),
              CTFFlagIndicator: AllowEmptyString(
                Type.Union([Type.Literal("Y")])
              ),
              FloodFlagIndicator: AllowEmptyString(
                Type.Union([Type.Literal("Y")])
              ),
              UsersEmailAddress: StringField,
              ProductCode: AllowEmptyString(Type.Union([Type.Literal("A6")])),
              AcceptedAcknowledgmentIndicator: BooleanField
            })
          )
        })
      )
    })
  )
});

export const PropertySchema = Type.Intersect([
  BaseEntitySchema,
  PropertyFieldsSchema
]);

export const PropertyUpdate = OmitUpdateSchema(PropertySchema);
export const PropertyCreate = OmitCreateSchema(PropertySchema);

export const getOperationPropertySchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  return op === "create" ? PropertyCreate : PropertyUpdate;
};

export const CreateSiteXRequestParams = Type.Object({
  taskId: StringField,
  propertyId: StringField,
  location: Type.Partial(
    Type.Object({
      fips: StringField,
      assessorParcelNumber: StringField,
      addressLineText: StringField,
      addressUnitIdentifier: StringField,
      cityName: StringField,
      stateCode: StringField,
      postalCode: StringField
    })
  )
});
