const colors = {
  light: {
    bg: {
      main: "#FFFFFF",
      secondary: "#14B8E8",
      emptyInput: "#FAF089"
    },
    notifications: {
      error: "#F56565",
      success: "#48BB78",
      warning: "#FAF089",
      info: "#4299E1"
    },
    portfolioAction: {
      valid: "#48BB78",
      deny:"#F56565",
      followup: "#FAF089",
      override: "#010101",
      none: "#FFFFFF"
    }
  }
};
const components = {
  light: {
    icons: {
      warningIcon: {
        on: "orange.200",
        off: "white",
        offSecondary: "blackAlpha.300"
      }
    },
    task: {
      generation: {
        old: "red.300",
        manual: "purple.300",
        latest: "blue.300"
      }
    },
    button: {
      portfolioAction: {
        valid: { colorScheme: "green", variant: "solid" },
        followup: { bgColor: "#FAF089", variant: "solid" },
        override: { bgColor: "black", color: "white" },
        deny: { bgColor: "red.300", color: "white" },
        none: { colorScheme: "black", color: "black", variant: "outline" }
      },
      navigation:{
        showProperties:"purple.200",
        goToDeal:"blue.100",
        goToParty:"pink.200"
      },
      success: {
        bgColor: "#D3F1E2",
        color: "green.700"
        // colorScheme: "green",

        // variant: "outline"
      },
      primary: {
        colorScheme: "blue",
        variant: "outline"
      },
      secondary: {
        colorScheme: "purple",
        variant: "outline"
      },
      scaleChart: {
        bgColor: "blue.500"
      },
      generate: {
        colorScheme: "blue",
        variant: "solid"
      }
    },
    treeLayout: {
      lineColor: "#384a8c"
    },
    treeNode: {
      selected: {
        bg: "#b1fbff"
      },
      duplicate: {
        bg: "#ffb1fc"
      },
      unselected: {
        bg: "#FFFFFF",
        border: "1px solid black"
      }
    },
    sidebar: {
      tabs: {
        text: {
          selected: "#0157FE"
        }
      }
    },
    table:{
      rows:{
        selected:{
          bg:"blue.100",
          border:{
            main: "gray.100",
            secondary: "blue.400",
          }
        }
      }
    }
  }
};

const elphiTheme = {
  colors,
  components
};
export default elphiTheme;
