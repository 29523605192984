import { ElphiEntityType } from "@elphi/types";
import { NOT_AVAILABLE } from "../../../constants/common";
import { DNDRow } from "../../drag-and-drop/dnd.types";
import { fieldSelection } from "../../utils/field-selection/builders/field-selection-rule.builder";

const task = fieldSelection.build(ElphiEntityType.task).map((x) => {
  return {
    id: `${ElphiEntityType.task}-${x.value}`,
    entityType: ElphiEntityType.task,
    label: x.label,
    path: x.value
  };
});

const party = fieldSelection.build(ElphiEntityType.party).map((x) => {
  return {
    id: `${ElphiEntityType.party}-${x.value}`,
    entityType: ElphiEntityType.party,
    label: x.label,
    path: x.value
  };
});

const deal = fieldSelection.build(ElphiEntityType.deal).map((x) => {
  return {
    id: `${ElphiEntityType.deal}-${x.value}`,
    entityType: ElphiEntityType.deal,
    label: x.label,
    path: x.value
  };
});

const property = fieldSelection.build(ElphiEntityType.property).map((x) => {
  return {
    id: `${ElphiEntityType.property}-${x.value}`,
    entityType: ElphiEntityType.property,
    label: x.label,
    path: x.value
  };
});

const asset = fieldSelection.build(ElphiEntityType.asset).map((x) => {
  return {
    id: `${ElphiEntityType.asset}-${x.value}`,
    entityType: ElphiEntityType.asset,
    label: x.label,
    path: x.value
  };
});

const statement = fieldSelection.build(ElphiEntityType.statement).map((x) => {
  return {
    id: `${ElphiEntityType.statement}-${x.value}`,
    entityType: ElphiEntityType.statement,
    label: x.label,
    path: x.value
  };
});

// still not implemented in audit log

// const serviceProvider = fieldSelection
//   .build(ElphiEntityType.serviceProvider)
//   .map((x) => {
//     return {
//       id: `${ElphiEntityType.serviceProvider}-${x.value}`,
//       entityType: ElphiEntityType.serviceProvider,
//       label: x.label,
//       path: x.value
//     };
//   });

const dealParty = fieldSelection.build(ElphiEntityType.dealParty).map((x) => {
  return {
    id: `${ElphiEntityType.dealParty}-${x.value}`,
    entityType: ElphiEntityType.dealParty,
    label: x.label,
    path: x.value
  };
});

const dealProperty = fieldSelection
  .build(ElphiEntityType.dealProperty)
  .map((x) => {
    return {
      id: `${ElphiEntityType.dealProperty}-${x.value}`,
      entityType: ElphiEntityType.dealProperty,
      label: x.label,
      path: x.value
    };
  });

const partyRelation = fieldSelection
  .build(ElphiEntityType.partyRelation)
  .map((x) => {
    return {
      id: `${ElphiEntityType.partyRelation}-${x.value}`,
      entityType: ElphiEntityType.partyRelation,
      label: x.label,
      path: x.value
    };
  });

export type FieldGroupDNDRow = DNDRow<{
  entityType: ElphiEntityType;
  label: string;
  path: string;
}>;

export const dataMap: Map<string, FieldGroupDNDRow> = new Map(
  [
    ...task,
    ...party,
    ...deal,
    ...property,
    ...asset,
    ...statement,
    ...dealParty,
    ...dealProperty,
    ...partyRelation
  ].map((x) => [x.id, x])
);

export const dataSet = Array.from(dataMap.values());

export const excludeFieldsFromDataSet = (
  selectedFields: { fieldPath: string; entityType: ElphiEntityType }[]
) => {
  return dataSet.filter((x) => {
    return !selectedFields.some(
      (v) => `${v.entityType}-${v.fieldPath}` === x.id
    );
  });
};
export const buildTargetFields = (
  selectedFields: { fieldPath: string; entityType: ElphiEntityType }[]
) => {
  return selectedFields.map((v) => {
    const id = `${v.entityType}-${v.fieldPath}`;
    return {
      id,
      entityType: v.entityType,
      path: v.fieldPath,
      label: dataMap.get(id)?.label || NOT_AVAILABLE
    };
  });
};
