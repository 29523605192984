import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  AuditEvent,
  AuditEventEntityType,
  AuditEventFieldType,
  AuditEventOperation
} from "@elphi/types";
import { NOT_AVAILABLE } from "../../../../constants/common";
import { DeleteFileIcon, EmptyFileIcon } from "../../../file-uploader/Icons";
import { ChecklistIcon, PlusIcon, WingGrayIcon } from "../../../icons";

const iconFieldTypeMap: Partial<Record<AuditEventFieldType, JSX.Element>> = {
  [AuditEventFieldType.IntegrationStatus]: <WingGrayIcon mr={1} w={7} h={7} />,
  [AuditEventFieldType.UploadedFile]: <EmptyFileIcon mr={1} w={7} h={7} />,
  [AuditEventFieldType.DeletedFile]: (
    <DeleteFileIcon marginLeft={"-2px"} mr={1} w={7} h={6} />
  )
};

const iconOperationMap = {
  [AuditEventOperation.Created]: (_: AuditEventEntityType) => (
    <PlusIcon mr={2} w={6} h={5} />
  ),
  [AuditEventOperation.Updated]: (e: AuditEventEntityType) => {
    return e === AuditEventEntityType.Task ? (
      <ChecklistIcon mr={2} w={6} h={6} />
    ) : (
      <EditIcon mr={3} w={5} h={5} />
    );
  },
  [AuditEventOperation.Deleted]: (_: AuditEventEntityType) => (
    <DeleteIcon mr={3} w={5} h={5} />
  )
};

export const EventRowIcon = (props: { event: AuditEvent }) => {
  const { event } = props;
  const { operation, fieldType } = event;
  const IconComponent =
    iconFieldTypeMap[fieldType || ""] ||
    iconOperationMap[operation]?.(event.entityType);
  return <>{IconComponent || NOT_AVAILABLE}</>;
};
