import {
  FloodCertificateVendorType,
  IntegrationType,
  PropertyFields,
  PropertyReportVendorType,
  RentalDataVendorType
} from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const propertyAddressLineTextFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "AddressLineText"]
> = ["Address", "AddressLineText"];

export const propertyAddressUnitIdentifierFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "AddressUnitIdentifier"]
> = ["Address", "AddressUnitIdentifier"];

export const propertyCityNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "CityName"]
> = ["Address", "CityName"];

export const propertyCountyNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "CountyName"]
> = ["Address", "CountyName"];

export const propertyStateNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "StateName"]
> = ["Address", "StateName"];

export const propertyStateCodeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "StateCode"]
> = ["Address", "StateCode"];

export const propertyPostalCodeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "PostalCode"]
> = ["Address", "PostalCode"];

export const propertyTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyType"]
> = ["PropertyType"];

export const financedUnitCountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FinancedUnitCount"]
> = ["FinancedUnitCount"];

export const parcelNumberFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["ParcelNumber"]
> = ["ParcelNumber"];

export const legalDescriptionFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "LegalDescription"]
> = ["Address", "LegalDescription"];

export const propertyLotIdentifierFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "PropertyLotIdentifier"]
> = ["Address", "PropertyLotIdentifier"];

export const propertyBlockIdentifierFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "PropertyBlockIdentifier"]
> = ["Address", "PropertyBlockIdentifier"];

export const propertySubdivisionIdentifierFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "PropertySubdivisionIdentifier"]
> = ["Address", "PropertySubdivisionIdentifier"];

export const propertyRightsOwnershipTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyRightsOwnershipType"]
> = ["PropertyRightsOwnershipType"];

export const condoWarrantableIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["CondoWarrantableIndicator"]
> = ["CondoWarrantableIndicator"];

export const condoCertificateFeesCollectedIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["CondoCertificateFeesCollectedIndicator"]
> = ["CondoCertificateFeesCollectedIndicator"];

export const condoCertificateCommentsFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["CondoCertificateComments"]
> = ["CondoCertificateComments"];

export const propertyLoanPurposeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyLoanPurpose"]
> = ["PropertyLoanPurpose"];

export const purchasePriceAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PurchasePriceAmount"]
> = ["PurchasePriceAmount"];

export const outstandingLoanPayoffAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["OutstandingLoanPayoffAmount"]
> = ["OutstandingLoanPayoffAmount"];

export const defaultInterestPaymentsOrFeesNotedIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["defaultInterestPaymentsOrFeesNotedIndicator"]
> = ["defaultInterestPaymentsOrFeesNotedIndicator"];

export const allocatedLoanAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["aggregations", "AllocatedLoanAmount"]
> = ["aggregations", "AllocatedLoanAmount"];

export const bridgeLTVPercentFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["aggregations", "BridgeLTVPercent"]
> = ["aggregations", "BridgeLTVPercent"];
export const ltcRatePercentFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["aggregations", "LTCRatePercent"]
> = ["aggregations", "LTCRatePercent"];
export const pricingEngineLTARVRatePercentFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["aggregations", "PricingEngineLTARVRatePercent"]
> = ["aggregations", "PricingEngineLTARVRatePercent"];

export const renovationCostAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["RenovationCostAmount"]
> = ["RenovationCostAmount"];

export const constructionCostAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["ConstructionCostAmount"]
> = ["ConstructionCostAmount"];

export const constructionCostCommentsFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["ConstructionCostComments"]
> = ["ConstructionCostComments"];

export const originalPurchaseDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["OriginalPurchaseDate"]
> = ["OriginalPurchaseDate"];

export const originalPurchasePriceAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["OriginalPurchasePriceAmount"]
> = ["OriginalPurchasePriceAmount"];

export const annualHomeownersAssociationFeeAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["AnnualHomeownersAssociationFeeAmount"]
> = ["AnnualHomeownersAssociationFeeAmount"];

export const propertyDetailCommentsFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyDetailComments"]
> = ["PropertyDetailComments"];

export const borrowerRenovationCostsAfterPurchaseAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["BorrowerRenovationCostsAfterPurchaseAmount"]
> = ["BorrowerRenovationCostsAfterPurchaseAmount"];

export const contractExecutionDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["ContractExecutionDate"]
> = ["ContractExecutionDate"];

export const contractClosingDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["ContractClosingDate"]
> = ["ContractClosingDate"];

export const psaExtensionRequestedIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PSAExtensionRequestedIndicator"]
> = ["PSAExtensionRequestedIndicator"];

export const psaExtensionFormIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PSAExtensionFormIndicator"]
> = ["PSAExtensionFormIndicator"];

export const psaAssignmentContractIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PSAAssignmentContractIndicator"]
> = ["PSAAssignmentContractIndicator"];

export const psaAddendumIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PSAAddendumIndicator"]
> = ["PSAAddendumIndicator"];

export const psaSellerOwnerRecordMismatchIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PSASellerOwnerRecordMismatchIndicator"]
> = ["PSASellerOwnerRecordMismatchIndicator"];

export const PSABuyerBorrowerMismatchFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PSABuyerBorrowerMismatchIndicator"]
> = ["PSABuyerBorrowerMismatchIndicator"];

export const PSAExpirationDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PSAExpirationDate"]
> = ["PSAExpirationDate"];

export const payoffExpirationDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PayoffExpirationDate"]
> = ["PayoffExpirationDate"];

export const mortgageeFullNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["MortgageeFullName"]
> = ["MortgageeFullName"];

export const mortgageeContactFullNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["MortgageeContactFullName"]
> = ["MortgageeContactFullName"];

export const mortgageeContactPointTelephoneFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["MortgageeContactPointTelephoneValue"]
> = ["MortgageeContactPointTelephoneValue"];

export const mortgageeContactPointEmailValueFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["MortgageeContactPointEmailValue"]
> = ["MortgageeContactPointEmailValue"];

export const cemaRefinanceIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["CEMARefinanceIndicator"]
> = ["CEMARefinanceIndicator"];

export const appraisalValueDerivationTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "AppraisalValueDerivationType"]
> = ["Appraisal", "AppraisalValueDerivationType"];

export const propertyValuationAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "PropertyValuationAmount"]
> = ["Appraisal", "PropertyValuationAmount"];

export const subjectToPropertyValuationAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "SubjectToPropertyValuationAmount"]
> = ["Appraisal", "SubjectToPropertyValuationAmount"];

export const appraisalOrderedDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "AppraisalOrderedDate"]
> = ["Appraisal", "AppraisalOrderedDate"];

export const appraisalCompletedDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "AppraisalCompletedDate"]
> = ["Appraisal", "AppraisalCompletedDate"];

export const appraisalEffectiveDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "AppraisalEffectiveDate"]
> = ["Appraisal", "AppraisalEffectiveDate"];

export const appraisalExpirationDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "AppraisalExpirationDate"]
> = ["Appraisal", "AppraisalExpirationDate"];

export const appraisalTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "AppraisalType"]
> = ["Appraisal", "AppraisalType"];

export const qualityRatingTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "QualityRatingType"]
> = ["Appraisal", "QualityRatingType"];

export const conditionRatingTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "ConditionRatingType"]
> = ["Appraisal", "ConditionRatingType"];

export const propertyAreaValueFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "PropertyAreaValue"]
> = ["Appraisal", "PropertyAreaValue"];

export const totalBedroomCountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "TotalBedroomCount"]
> = ["Appraisal", "TotalBedroomCount"];

export const totalBathroomCountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "TotalBathroomCount"]
> = ["Appraisal", "TotalBathroomCount"];

export const propertyStructureBuiltYearFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "PropertyStructureBuiltYear"]
> = ["Appraisal", "PropertyStructureBuiltYear"];

export const countyNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Address", "CountyName"]
> = ["Address", "CountyName"];

export const specificZoningClassificationFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "SpecificZoningClassification"]
> = ["Appraisal", "SpecificZoningClassification"];

export const propertyZoningDescriptionFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "PropertyZoningDescription"]
> = ["Appraisal", "PropertyZoningDescription"];

export const propertyZoningComplianceRatingTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "PropertyZoningComplianceRatingType"]
> = ["Appraisal", "PropertyZoningComplianceRatingType"];

export const appraisalCoordinatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "AppraisalCoordinator"]
> = ["Appraisal", "AppraisalCoordinator"];

export const appraisalForm1007RequiredIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "AppraisalForm1007RequiredIndicator"]
> = ["Appraisal", "AppraisalForm1007RequiredIndicator"];

export const appraisalManagementRepresentativeFullNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["AppraisalManagementCompany", "RepresentativeFullName"]
> = ["AppraisalManagementCompany", "RepresentativeFullName"];

export const internalValuationEligibilityIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "InternalValuationEligibilityIndicator"]
> = ["Appraisal", "InternalValuationEligibilityIndicator"];

export const appraisalManagementContactPointTelephoneValueFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["AppraisalManagementCompany", "ContactPointTelephoneValue"]
> = ["AppraisalManagementCompany", "ContactPointTelephoneValue"];

export const appraisalManagementContactPointEmailValueFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["AppraisalManagementCompany", "ContactPointEmailValue"]
> = ["AppraisalManagementCompany", "ContactPointEmailValue"];

export const appraisalManagementCompanyNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["AppraisalManagementCompany", "AppraisalManagementCompanyName"]
> = ["AppraisalManagementCompany", "AppraisalManagementCompanyName"];

export const propertyAccessInformationFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["Appraisal", "PropertyAccessInformation"]
> = ["Appraisal", "PropertyAccessInformation"];

export const specialFloodHazardAreaIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "SpecialFloodHazardAreaIndicator"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "SpecialFloodHazardAreaIndicator"
];

export const floodInsuranceDwellingCoverageAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsurance", "DwellingCoverageAmount"]
> = ["FloodInsurance", "DwellingCoverageAmount"];

export const floodInsurancePremiumAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsurance", "InsurancePremiumAmount"]
> = ["FloodInsurance", "InsurancePremiumAmount"];
export const floodInsurancePremiumOutstandingAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsurance", "InsurancePremiumOutstandingAmount"]
> = ["FloodInsurance", "InsurancePremiumOutstandingAmount"];

export const floodInsurancePremiumPaymentTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsurance", "InsurancePremiumPaymentType"]
> = ["FloodInsurance", "InsurancePremiumPaymentType"];

export const floodInsurancePolicyNumberFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsurance", "PolicyNumber"]
> = ["FloodInsurance", "PolicyNumber"];

export const floodInsurancePolicyExpirationDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsurance", "PolicyExpirationDate"]
> = ["FloodInsurance", "PolicyExpirationDate"];

export const floodInsuranceNextPaymentDueDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsurance", "NextPaymentDueDate"]
> = ["FloodInsurance", "NextPaymentDueDate"];

export const floodInsuranceDeductibleAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsurance", "DeductibleAmount"]
> = ["FloodInsurance", "DeductibleAmount"];

export const floodInsuranceFullNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsuranceAgency", "FullName"]
> = ["FloodInsuranceAgency", "FullName"];

export const floodInsuranceRepresentativeFullNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsuranceAgency", "RepresentativeFullName"]
> = ["FloodInsuranceAgency", "RepresentativeFullName"];

export const floodInsuranceContactPointTelephoneValueFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsuranceAgency", "ContactPointTelephoneValue"]
> = ["FloodInsuranceAgency", "ContactPointTelephoneValue"];

export const floodInsuranceContactPointEmailValueFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsuranceAgency", "ContactPointEmailValue"]
> = ["FloodInsuranceAgency", "ContactPointEmailValue"];

export const floodInsuranceAddressLineTextFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsuranceAgency", "AddressLineText"]
> = ["FloodInsuranceAgency", "AddressLineText"];

export const floodInsuranceAddressUnitIdentifierFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsuranceAgency", "AddressUnitIdentifier"]
> = ["FloodInsuranceAgency", "AddressUnitIdentifier"];

export const floodInsuranceCityNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsuranceAgency", "CityName"]
> = ["FloodInsuranceAgency", "CityName"];

export const floodInsuranceStateCodeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsuranceAgency", "StateCode"]
> = ["FloodInsuranceAgency", "StateCode"];

export const floodInsurancePostalCodeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["FloodInsuranceAgency", "PostalCode"]
> = ["FloodInsuranceAgency", "PostalCode"];

export const propertyInsuranceDwellingCoverageAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "DwellingCoverageAmount"]
> = ["PropertyInsurance", "DwellingCoverageAmount"];

export const propertyInsuranceLiabilityCoverageAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "LiabilityCoverageAmount"]
> = ["PropertyInsurance", "LiabilityCoverageAmount"];

export const propertyInsuranceReplacementCoverageIndicatorFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "ReplacementCoverageIndicator"]
> = ["PropertyInsurance", "ReplacementCoverageIndicator"];

export const propertyInsuranceBuilderRiskInsuranceFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "BuilderRiskInsurance"]
> = ["PropertyInsurance", "BuilderRiskInsurance"];

export const propertyInsuranceRentalLossCoverageAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "RentalLossCoverageAmount"]
> = ["PropertyInsurance", "RentalLossCoverageAmount"];

export const propertyInsurancePremiumAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "InsurancePremiumAmount"]
> = ["PropertyInsurance", "InsurancePremiumAmount"];
export const propertyInsurancePremiumOutstandingAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "InsurancePremiumOutstandingAmount"]
> = ["PropertyInsurance", "InsurancePremiumOutstandingAmount"];

export const propertyInsurancePremiumPaymentTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "InsurancePremiumPaymentType"]
> = ["PropertyInsurance", "InsurancePremiumPaymentType"];

export const propertyInsurancePolicyIdentifierFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "PropertyInsurancePolicyIdentifier"]
> = ["PropertyInsurance", "PropertyInsurancePolicyIdentifier"];

export const propertyInsurancePolicyExpirationDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "PolicyExpirationDate"]
> = ["PropertyInsurance", "PolicyExpirationDate"];

export const propertyInsuranceNextPaymentDueDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "NextPaymentDueDate"]
> = ["PropertyInsurance", "NextPaymentDueDate"];

export const propertyInsuranceDeductibleAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsurance", "DeductibleAmount"]
> = ["PropertyInsurance", "DeductibleAmount"];

export const propertyInsuranceAgentFullNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsuranceAgency", "InsuranceAgentFullName"]
> = ["PropertyInsuranceAgency", "InsuranceAgentFullName"];

export const propertyInsuranceFullNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsuranceAgency", "FullName"]
> = ["PropertyInsuranceAgency", "FullName"];

export const propertyInsuranceContactPointTelephoneFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsuranceAgency", "ContactPointTelephoneValue"]
> = ["PropertyInsuranceAgency", "ContactPointTelephoneValue"];

export const propertyInsuranceContactPointEmailFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsuranceAgency", "ContactPointEmailValue"]
> = ["PropertyInsuranceAgency", "ContactPointEmailValue"];

export const propertyInsuranceAddressLineTextFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsuranceAgency", "AddressLineText"]
> = ["PropertyInsuranceAgency", "AddressLineText"];

export const propertyInsuranceAddressUnitIdentifierFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsuranceAgency", "AddressUnitIdentifier"]
> = ["PropertyInsuranceAgency", "AddressUnitIdentifier"];

export const propertyInsuranceCityNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsuranceAgency", "CityName"]
> = ["PropertyInsuranceAgency", "CityName"];

export const propertyInsuranceStateCodeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsuranceAgency", "StateCode"]
> = ["PropertyInsuranceAgency", "StateCode"];

export const propertyInsurancePostalCodeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInsuranceAgency", "PostalCode"]
> = ["PropertyInsuranceAgency", "PostalCode"];

export const totalCostAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["aggregations", "TotalCostAmount"]
> = ["aggregations", "TotalCostAmount"];

export const netWireAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["NetWireAmount"]
> = ["NetWireAmount"];
export const annualTaxAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["aggregations", "AnnualTaxAmount"]
> = ["aggregations", "AnnualTaxAmount"];
export const leaseStatusTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["LeaseStatusType"]
> = ["LeaseStatusType"];

export const totalTaxEscrowAmountFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["aggregations", "TotalTaxEscrowAmount"]
> = ["aggregations", "TotalTaxEscrowAmount"];
export const internalL1CRefinanceFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["InternalL1CRefinanceIndicator"]
> = ["InternalL1CRefinanceIndicator"];

export const leaseStrategyFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["LeaseStrategy"]
> = ["LeaseStrategy"];
export const rentalLTVPercentFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["RentalLTVPercent"]
> = ["RentalLTVPercent"];
export const constructionBudgetAssessmentFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["ConstructionBudgetAssessment"]
> = ["ConstructionBudgetAssessment"];
export const annualCapitalExpenditureFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["AnnualCapitalExpenditure"]
> = ["AnnualCapitalExpenditure"];
export const orderNumberFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "OrderNumber"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "OrderNumber"
];
export const orderStatusFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "OrderStatus"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "OrderStatus"
];
export const NFIPMapCommunityNumberFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "NFIPMapCommunityNumber"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "NFIPMapCommunityNumber"
];

export const NFIPLegalCommunityNumberFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "NFIPLegalCommunityNumber"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "NFIPLegalCommunityNumber"
];
export const NFIPMapCommunityNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "NFIPMapCommunityName"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "NFIPMapCommunityName"
];
export const NFIPMapPanelNumberFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "NFIPMapPanelNumber"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "NFIPMapPanelNumber"
];

export const NFIPLegalCommunityNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "NFIPLegalCommunityName"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "NFIPLegalCommunityName"
];
export const floodMapDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "FloodMapDate"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "FloodMapDate"
];
export const floodZoneFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "FloodZone"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "FloodZone"
];
export const determinationDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "DeterminationDate"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "DeterminationDate"
];
export const resultDescriptionFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "ResultDescription"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "ResultDescription"
];
export const resultCodeFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "ResultCode"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "ResultCode"
];
export const floodCheckStatusFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "FloodCheckStatus"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "FloodCheckStatus"
];
export const propertyMSAMDFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "PropertyMSAMD"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "PropertyMSAMD"
];
export const TSDNCommentsFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "TSDNComments"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "TSDNComments"
];
export const requestedInformationFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "RequestedInformation"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "RequestedInformation"
];
export const returnDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "ReturnDate"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "ReturnDate"
];
export const usersEmailAddressFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "Integrations",
    IntegrationType.FloodCertificate,
    FloodCertificateVendorType.ServiceLink,
    "UsersEmailAddress"
  ]
> = [
  "Integrations",
  IntegrationType.FloodCertificate,
  FloodCertificateVendorType.ServiceLink,
  "UsersEmailAddress"
];
export const propertySiteXAddressLineTextFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.PropertyReport,
    PropertyReportVendorType.SiteX,
    "addressLineText"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.PropertyReport,
  PropertyReportVendorType.SiteX,
  "addressLineText"
];
export const propertySiteXAddressUnitIdentifierFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.PropertyReport,
    PropertyReportVendorType.SiteX,
    "addressUnitIdentifier"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.PropertyReport,
  PropertyReportVendorType.SiteX,
  "addressUnitIdentifier"
];
export const propertySiteXCityNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.PropertyReport,
    PropertyReportVendorType.SiteX,
    "cityName"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.PropertyReport,
  PropertyReportVendorType.SiteX,
  "cityName"
];
export const propertySiteXStateCodeFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.PropertyReport,
    PropertyReportVendorType.SiteX,
    "stateCode"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.PropertyReport,
  PropertyReportVendorType.SiteX,
  "stateCode"
];
export const propertySiteXPostalCodeFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.PropertyReport,
    PropertyReportVendorType.SiteX,
    "postalCode"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.PropertyReport,
  PropertyReportVendorType.SiteX,
  "postalCode"
];
export const propertySiteXCountyNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.PropertyReport,
    PropertyReportVendorType.SiteX,
    "countyName"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.PropertyReport,
  PropertyReportVendorType.SiteX,
  "countyName"
];
export const propertySiteXAssessorParcelNumberFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.PropertyReport,
    PropertyReportVendorType.SiteX,
    "assessorParcelNumber"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.PropertyReport,
  PropertyReportVendorType.SiteX,
  "assessorParcelNumber"
];
export const propertySiteXFipsFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.PropertyReport,
    PropertyReportVendorType.SiteX,
    "fips"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.PropertyReport,
  PropertyReportVendorType.SiteX,
  "fips"
];
export const propertySiteXOrderStatusFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.PropertyReport,
    PropertyReportVendorType.SiteX,
    "orderStatus"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.PropertyReport,
  PropertyReportVendorType.SiteX,
  "orderStatus"
];
export const propertySiteXOrderCompletedOnFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.PropertyReport,
    PropertyReportVendorType.SiteX,
    "orderCompletedOn"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.PropertyReport,
  PropertyReportVendorType.SiteX,
  "orderCompletedOn"
];

export const propertyRentalDataRentRangeConfidenceScoreFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.RentalData,
    RentalDataVendorType.RentRange,
    "confidenceScore"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.RentalData,
  RentalDataVendorType.RentRange,
  "confidenceScore"
];

export const propertyRentalDataRentRangeOrderStatusFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.RentalData,
    RentalDataVendorType.RentRange,
    "orderStatus"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.RentalData,
  RentalDataVendorType.RentRange,
  "orderStatus"
];

export const propertyRentalDataRentRangeErrorMessageFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.RentalData,
    RentalDataVendorType.RentRange,
    "errorMessage"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.RentalData,
  RentalDataVendorType.RentRange,
  "errorMessage"
];

export const propertyRentalDataRentRangeRequestIdFieldPath: ObjectKeyValidation<
  PropertyFields,
  [
    "aggregations",
    "Integrations",
    IntegrationType.RentalData,
    RentalDataVendorType.RentRange,
    "requestId"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.RentalData,
  RentalDataVendorType.RentRange,
  "requestId"
];
