import { Box, Flex, Text } from "@chakra-ui/react";
import { AuditEvent } from "@elphi/types";
import { NOT_AVAILABLE } from "../../../../constants/common";
import { printDateTime } from "../../../../firebase/firebase.utils";
import { EventEntityPath, EventEntityValue } from "./EventRowEntity";
import { EventRowIcon } from "./EventRowIcon";

export const EventRowBuilder = (props: { event: AuditEvent }) => {
  const { event } = props;
  return (
    <Flex
      alignItems="center"
      borderBottomColor={"gray.100"}
      borderBottomWidth={1}
      py={4}
    >
      <EventRowIcon event={event} />
      <Flex direction="column" textAlign="left" width={"65%"}>
        <Box fontSize="14px" fontWeight={700}>
          <EventEntityPath event={event} />
        </Box>
        <Flex alignItems="center" fontSize="14px" fontWeight="400">
          <EventEntityValue event={event} />
        </Flex>
      </Flex>
      <Flex direction="column" textAlign="right" ml={4} width={"35%"}>
        <Text fontSize="14px" fontWeight={700}>
          {event.userEmail || event.createdUID || NOT_AVAILABLE}
        </Text>
        <Text fontSize="14px" fontWeight={700} color="gray.500">
          {printDateTime(event.createdAt) || NOT_AVAILABLE}
        </Text>
      </Flex>
    </Flex>
  );
};
