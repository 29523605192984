import { Box } from "@chakra-ui/react";
import { AuditEvent, AuditEventOperation } from "@elphi/types";
import { NOT_AVAILABLE } from "../../../../constants/common";
import {
  fieldTypeMap,
  getDiscussionPrettyIdentifier,
  getEntityPrettyIdentifier,
  getFieldLabel
} from "./eventRow.utils";

export const EntityPath = (props: { event: AuditEvent }) => {
  const { event } = props;
  const { operation, fieldType } = event;
  const prettyIdentifier = getEntityPrettyIdentifier(event);
  if (
    operation === AuditEventOperation.Created ||
    operation === AuditEventOperation.Deleted
  ) {
    return <Box>{prettyIdentifier}</Box>;
  }
  if (operation === AuditEventOperation.Updated) {
    const fieldLabel = getFieldLabel(event) || NOT_AVAILABLE;
    const fieldPrettyType = fieldType ? fieldTypeMap[fieldType] : NOT_AVAILABLE;
    return (
      <Box>{`${prettyIdentifier} | ${fieldLabel} | ${fieldPrettyType}`}</Box>
    );
  }
  return <>{NOT_AVAILABLE}</>;
};

export const DiscussionPath = (props: { event: AuditEvent }) => {
  const { event } = props;
  const { fieldType } = event;
  const prettyIdentifier = getDiscussionPrettyIdentifier(event);
  const fieldPrettyType = fieldType ? fieldTypeMap[fieldType] : NOT_AVAILABLE;
  return <Box>{`${prettyIdentifier} | Discussion | ${fieldPrettyType}`}</Box>;
};
