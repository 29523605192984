import { Box, Flex } from "@chakra-ui/react";
import { DndContext, DragOverlay, useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ViewportList from "react-viewport-list";
import {
  DNDPickerProps,
  DNDRow,
  SortableContainerProps,
  SortableRowProps
} from "./dnd.types";
import { useDNDPickerHooks } from "./dndPicker.hooks";

export const DNDPicker = <T extends DNDRow<object>>(
  props: DNDPickerProps<T>
) => {
  const { items, handleOnPick, HeaderComponent, RowComponent } = props;
  const {
    activeRow,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    closestCorners
  } = useDNDPickerHooks<T>({ items, handleOnPick });

  return (
    <Flex direction={"row"}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <SortableContainer
          id={"source"}
          items={items.source}
          HeaderComponent={HeaderComponent}
          RowComponent={RowComponent}
        />
        <Box m={1}></Box>
        <SortableContainer
          id={"target"}
          items={items.target}
          HeaderComponent={HeaderComponent}
          RowComponent={RowComponent}
        />
        <DragOverlay>
          {activeRow ? (
            <Box p={2} cursor={"grabbing"} background={"gray.100"}>
              <RowComponent item={activeRow} />
            </Box>
          ) : (
            <></>
          )}
        </DragOverlay>
      </DndContext>
    </Flex>
  );
};

const SortableContainer = <T extends DNDRow<object>>(
  props: SortableContainerProps<T>
) => {
  const { id, items, HeaderComponent } = props;
  const { setNodeRef } = useDroppable({ id });

  return (
    <SortableContext
      id={id}
      items={items.map((item) => item.id)}
      strategy={verticalListSortingStrategy}
    >
      <Flex
        ref={setNodeRef}
        flex={1}
        flexDirection={"column"}
        my={2}
        maxHeight={600}
        overflow={"auto"}
        borderWidth={1}
        borderRadius={"lg"}
      >
        <Flex bgColor={"gray.100"} position={"sticky"} top={0} p={2} zIndex={1}>
          <HeaderComponent />
        </Flex>
        <ViewportList items={items} withCache>
          {(item, i) => (
            <SortableRow key={item.id} item={item} index={i + 1} {...props} />
          )}
        </ViewportList>
      </Flex>
    </SortableContext>
  );
};

const SortableRow = <T extends DNDRow<object>>(props: SortableRowProps<T>) => {
  const { index, item, RowComponent } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  return (
    <Box
      _hover={{ background: "gray.200" }}
      ref={setNodeRef}
      transform={CSS.Transform.toString(transform)}
      transition={transition}
      {...attributes}
      {...listeners}
    >
      <Box m={2} cursor={"grab"}>
        <RowComponent item={item} index={index} />
      </Box>
    </Box>
  );
};
