import { DragHandleIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { FieldGroup } from "@elphi/types";
import { useEffect, useState } from "react";
import { useFieldGroupHooks } from "../../../hooks/fieldGroup.hooks";
import { DNDPicker } from "../../drag-and-drop/DNDPicker";
import { DNDPickerItems } from "../../drag-and-drop/dnd.types";
import ModalContainer from "../../modal-container/ModalContainer";
import { FuzzySearch } from "../../search/fuzzy/FuzzySearch";
import {
  buildTargetFields,
  dataSet,
  excludeFieldsFromDataSet,
  FieldGroupDNDRow
} from "../utils/fieldGroup.utils";

export const FieldGroupSelectionModal = (props: {
  selectedRow: FieldGroup;
  isShow: boolean;
  onClose: () => void;
}) => {
  const { updateGroup, updateResponse } = useFieldGroupHooks();
  const [items, setItems] = useState<DNDPickerItems<FieldGroupDNDRow>>({
    source: dataSet,
    target: []
  });

  useEffect(() => {
    if (props.isShow && props.selectedRow.fields) {
      const selectedFields = Object.values(props.selectedRow.fields || {});
      const source = excludeFieldsFromDataSet(selectedFields);
      const target = buildTargetFields(selectedFields);
      setItems({
        source,
        target
      });
    }
  }, [props.isShow, props.selectedRow.fields]);

  const handleOnPick = (r: {
    source: FieldGroupDNDRow[];
    target: FieldGroupDNDRow[];
  }) => {
    setItems(r);
  };

  const handleOnSearchResult = (result: FieldGroupDNDRow[]) => {
    const shouldExcludeFromResult = items.target.length;
    const source = shouldExcludeFromResult
      ? result.filter((x) => !items.target.some((item) => item.id === x.id))
      : result;
    setItems((prev) => ({
      ...prev,
      source
    }));
  };

  const handleOnSubmit = () => {
    const targetFields = items.target.reduce((acc, x, index) => {
      return {
        ...acc,
        [index]: {
          fieldPath: x.path,
          entityType: x.entityType
        }
      };
    }, {});
    updateGroup({
      id: props.selectedRow.id,
      fields: targetFields
    });
  };

  return (
    <ModalContainer
      maxWidth={1400}
      isShow={props.isShow}
      onCloseModal={props.onClose}
      body={
        <>
          <FuzzySearch
            label={"Search fields"}
            dataSet={dataSet}
            configuration={{
              keys: ["path", "label", "entityType"],
              threshold: 0.4,
              includeScore: true
            }}
            onResult={handleOnSearchResult}
            sanitizer={(q) => decodeURIComponent(encodeURIComponent(q))}
          />
          <DNDPicker
            items={items}
            handleOnPick={handleOnPick}
            HeaderComponent={Header}
            RowComponent={Row}
          />
        </>
      }
      submit={{
        showClose: true,
        onConfirm: handleOnSubmit,
        isLoading: updateResponse.isLoading
      }}
    />
  );
};

const Header = () => (
  <Flex flexFlow={"column nowrap"} w={"100%"}>
    <Flex>
      <Flex flex={1} justifyContent={"center"} alignItems={"center"}></Flex>
      <Flex flex={2} justifyContent={"center"} alignItems={"center"}>
        <Text fontWeight={600}>Index</Text>
      </Flex>
      <Flex flex={3} justifyContent={"center"} alignItems={"center"}>
        <Text fontWeight={600}>Entity</Text>
      </Flex>
      <Flex
        flex={16}
        justifyContent={"center"}
        alignItems={"center"}
        width={"400px"}
      >
        <Text fontWeight={600}>Field</Text>
      </Flex>
    </Flex>
  </Flex>
);

const Row = (props: { item: FieldGroupDNDRow; index?: number }) => (
  <Flex flexFlow={"column nowrap"} w={"100%"}>
    <Flex>
      <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
        <DragHandleIcon mb={1} />
      </Flex>
      <Flex flex={2} justifyContent={"center"} alignItems={"center"}>
        {props.index}
      </Flex>
      <Flex flex={3} justifyContent={"center"} alignItems={"center"}>
        {props.item.entityType}
      </Flex>
      <Flex flex={16} width={"1px"}>
        {props.item.label}
      </Flex>
    </Flex>
  </Flex>
);
