import { createIcon } from "@chakra-ui/react";

const customBookIcon = (r: { fill?: string; stroke: string }) =>
  createIcon({
    viewBox: "0 0 29 29",
    path: (
      <svg fill={r.fill} xmlns="http://www.w3.org/2000/svg" stroke={r.stroke}>
        <path
          d="M11.06 4.04689C9.08414 3.10353 6.32242 2.64142 2.625 2.62502C2.2763 2.62028 1.93444 2.72196 1.645 2.9165C1.40743 3.07708 1.21296 3.29358 1.0787 3.54695C0.94443 3.80032 0.874481 4.0828 0.875003 4.36955V20.0156C0.875003 21.0733 1.6275 21.8712 2.625 21.8712C6.51164 21.8712 10.4103 22.2343 12.7455 24.4415C12.7774 24.4718 12.8176 24.4921 12.8609 24.4998C12.9043 24.5075 12.949 24.5023 12.9894 24.4848C13.0299 24.4673 13.0643 24.4384 13.0884 24.4015C13.1125 24.3646 13.1252 24.3215 13.125 24.2774V5.84174C13.1251 5.71735 13.0985 5.5944 13.047 5.48118C12.9955 5.36797 12.9202 5.26713 12.8264 5.18549C12.2915 4.72821 11.6974 4.34522 11.06 4.04689V4.04689ZM26.355 2.91486C26.0654 2.7208 25.7235 2.61969 25.375 2.62502C21.6776 2.64142 18.9159 3.10135 16.94 4.04689C16.3027 4.34468 15.7084 4.72691 15.1731 5.1833C15.0794 5.26508 15.0044 5.36596 14.953 5.47915C14.9016 5.59235 14.875 5.71523 14.875 5.83955V24.2763C14.875 24.3187 14.8875 24.3601 14.9109 24.3953C14.9343 24.4306 14.9676 24.4582 15.0066 24.4746C15.0456 24.491 15.0886 24.4955 15.1302 24.4876C15.1718 24.4797 15.2101 24.4596 15.2403 24.43C16.6441 23.0355 19.1078 21.8695 25.3772 21.8701C25.8413 21.8701 26.2864 21.6857 26.6146 21.3575C26.9428 21.0293 27.1272 20.5842 27.1272 20.1201V4.3701C27.1278 4.08278 27.0577 3.79973 26.923 3.54593C26.7884 3.29213 26.5933 3.0754 26.355 2.91486V2.91486Z"
          strokeWidth="1.5"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const BookIcon = customBookIcon({ stroke: "#A2C1FF" });

export const BlackBookIcon = customBookIcon({ stroke: "#000000" });
