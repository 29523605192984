import { TimestampField } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { elphiInternalUsers } from "../components/audit-log/modal/list/eventList.utils";
import { EMPTY } from "../constants/common";
import { getTimestampFromFirestoreObject } from "../firebase/firebase.utils";
import { RootState } from "../redux/store";
import {
  AllEventsFilterState,
  AllEventsMode,
  auditEventApi,
  auditEventSlice,
  getModeKey,
  uniqCursorKey
} from "../redux/v2/audit-event";
import { usePaginationHooksV2 } from "../redux/v2/hooks/pagination.hooks.v2";
import { useLOSUserHooks } from "./losuser.hooks";

export const PAGE_SIZE_LIMIT = 10;

const useBaseHooks = () => {
  const { losUserState, buildUserOption } = useLOSUserHooks();
  const dispatch = useDispatch();
  const setCurrentCursor = (cursor?: string) => {
    dispatch(auditEventSlice.actions.setAllEventsCursor({ cursor }));
  };
  const setCurrentCursorHasMore = (hasMore: boolean) => {
    dispatch(auditEventSlice.actions.setAllEventsHasMore({ hasMore }));
  };
  const setCurrentFilter = (filter: AllEventsFilterState) => {
    dispatch(auditEventSlice.actions.setAllEventsFilter(filter));
  };

  const setCurrentHasNew = (hasNew: boolean) => {
    dispatch(auditEventSlice.actions.setAllEventsHasNew({ hasNew }));
  };

  const currentCursor = useSelector((state: RootState) => {
    const cursorKey = uniqCursorKey(state.auditEvent.current.filter);
    const modeKey = getModeKey(state.auditEvent);
    return (
      state.auditEvent.eventsFilterCursor?.[modeKey]?.filters?.[cursorKey]
        ?.cursor || EMPTY
    );
  });

  const currentHasMore = useSelector((state: RootState) => {
    const modeKey = getModeKey(state.auditEvent);
    const cursorKey = uniqCursorKey(state.auditEvent.current.filter);
    return !!state.auditEvent.eventsFilterCursor?.[modeKey]?.filters?.[
      cursorKey
    ]?.hasMore;
  });

  const currentHasNew = useSelector((state: RootState) => {
    const modeKey = getModeKey(state.auditEvent);
    return !!state.auditEvent.eventsFilterCursor?.[modeKey]?.hasNew;
  });

  const currentFilter = useSelector(
    (state: RootState) => state.auditEvent.current.filter
  );

  const auditEventState = useSelector((state: RootState) => state.auditEvent);

  const userOptions = useMemo(() => {
    return elphiInternalUsers.concat(
      Object.values(losUserState.entities).map(buildUserOption)
    );
  }, [losUserState.ids]);

  const resetFilters = () => {
    setCurrentFilter({
      auditTypes: [],
      entities: [],
      eventTypes: [],
      fields: [],
      users: []
    });
  };

  return {
    auditEventState,
    setCurrentCursor,
    setCurrentCursorHasMore,
    setCurrentHasNew,
    setCurrentFilter,
    currentCursor,
    currentHasMore,
    currentHasNew,
    currentFilter,
    userOptions,
    resetFilters
  };
};

export const useAllDealEventsHooks = () => {
  const dispatch = useDispatch();
  const {
    auditEventState,
    setCurrentCursor,
    setCurrentCursorHasMore,
    setCurrentHasNew,
    setCurrentFilter,
    currentCursor,
    currentHasMore,
    currentHasNew,
    currentFilter,
    userOptions,
    resetFilters
  } = useBaseHooks();

  const setSelectedDealId = (dealId: string) => {
    dispatch(auditEventSlice.actions.selectedDealId({ id: dealId }));
  };

  const selectedDealId = useSelector((state: RootState) =>
    String(
      state.auditEvent.mode === AllEventsMode.Deal
        ? state.auditEvent.selectedDealId || EMPTY
        : EMPTY
    )
  );

  const { nextPage, paginateApiResponse, hasMore } = usePaginationHooksV2({
    paginationProvider: auditEventApi.useLazyPaginateV2Query,
    limit: PAGE_SIZE_LIMIT,
    input: {
      dealId: selectedDealId,
      auditTypes: currentFilter.auditTypes,
      entityTypes: currentFilter.entities,
      eventTypes: currentFilter.eventTypes,
      fields: currentFilter.fields,
      users: currentFilter.users,
      createdAt: currentFilter.createdAt
    },
    setHasMore: (hasMore) => {
      setCurrentCursorHasMore(hasMore);
    },
    setCursor: (cursor) => {
      setCurrentCursor(cursor);
    },
    currentCursor
  });

  const isLoading =
    paginateApiResponse.isLoading || paginateApiResponse.isFetching;

  const filteredAllEvents = useMemo(() => {
    const filter = auditEventState.current.filter;

    const auditTypes = new Set(filter.auditTypes);
    const entities = new Set(filter.entities);
    const eventTypes = new Set(filter.eventTypes);
    const fields = new Set(filter.fields);
    const users = new Set(filter.users);

    const idsByDealId = auditEventState.ids.filter((id) =>
      auditEventState.entities[id]?.dealIds?.includes(selectedDealId)
    );

    const filtered = idsByDealId
      .map((id) => {
        const entity = auditEventState.entities[id];
        if (!entity) {
          return undefined;
        }

        const { fieldType, operation, entityType, fieldPath, createdUID } =
          entity;
        if (
          (auditTypes.size === 0 || (fieldType && auditTypes.has(fieldType))) &&
          (entities.size === 0 || entities.has(entityType)) &&
          (eventTypes.size === 0 || eventTypes.has(operation)) &&
          (fields.size === 0 || (fieldPath && fields.has(fieldPath))) &&
          (users.size === 0 || (createdUID && users.has(createdUID)))
        ) {
          return entity;
        }
        return undefined;
      })
      .filter(removeEmpty);

    return orderBy(
      filtered,
      (item) =>
        getTimestampFromFirestoreObject(item.createdAt as TimestampField),
      "desc"
    );
  }, [
    currentFilter.entities,
    currentFilter.fields,
    currentFilter.auditTypes,
    currentFilter.eventTypes,
    currentFilter.users,
    auditEventState.ids,
    selectedDealId
  ]);
  return {
    auditEventState,
    setCurrentCursor,
    setCurrentCursorHasMore,
    setCurrentHasNew,
    setCurrentFilter,
    currentCursor,
    currentHasMore,
    currentHasNew,
    currentFilter,
    userOptions,
    selectedDealId,
    setSelectedDealId,
    nextPage,
    hasMore,
    isLoading,
    filteredAllEvents,
    resetFilters
  };
};

export const useAllFieldEventsHooks = () => {
  const dispatch = useDispatch();

  const {
    auditEventState,
    setCurrentCursor,
    setCurrentCursorHasMore,
    setCurrentHasNew,
    setCurrentFilter,
    currentCursor,
    currentHasMore,
    currentHasNew,
    currentFilter,
    userOptions,
    resetFilters
  } = useBaseHooks();

  const selectedFieldId = useSelector((state: RootState) =>
    String(
      state.auditEvent.mode === AllEventsMode.Field
        ? state.auditEvent.selectedFieldId || EMPTY
        : EMPTY
    )
  );

  const selectedEntityId = useSelector((state: RootState) =>
    String(
      state.auditEvent.mode === AllEventsMode.Field
        ? state.auditEvent.selectedEntityId || EMPTY
        : EMPTY
    )
  );

  const setSelectedEntityField = (r: { entityId: string; fieldId: string }) => {
    dispatch(auditEventSlice.actions.setSelectedEntityField(r));
  };

  const { nextPage, paginateApiResponse, hasMore } = usePaginationHooksV2({
    paginationProvider: auditEventApi.useLazyPaginateV2Query,
    limit: PAGE_SIZE_LIMIT,
    input: {
      entityId: selectedEntityId,
      fields: currentFilter.fields,
      users: currentFilter.users,
      createdAt: currentFilter.createdAt
    },
    setHasMore: (hasMore) => {
      setCurrentCursorHasMore(hasMore);
    },
    setCursor: (cursor) => {
      setCurrentCursor(cursor);
    },
    currentCursor
  });

  const isLoading =
    paginateApiResponse.isLoading || paginateApiResponse.isFetching;

  const filteredAllEvents = useMemo(() => {
    const filter = auditEventState.current.filter;
    const fields = new Set(filter.fields);
    const users = new Set(filter.users);

    const filtered = auditEventState.ids
      .map((id) => {
        const entity = auditEventState.entities[id];
        if (!entity) {
          return undefined;
        }

        const { fieldPath, createdUID, entityId } = entity;
        if (
          entityId === selectedEntityId &&
          (fields.size === 0 || (fieldPath && fields.has(fieldPath))) &&
          (users.size === 0 || (createdUID && users.has(createdUID)))
        ) {
          return entity;
        }
        return undefined;
      })
      .filter(removeEmpty);

    return orderBy(
      filtered,
      (item) =>
        getTimestampFromFirestoreObject(item.createdAt as TimestampField),
      "desc"
    );
  }, [
    currentFilter.fields,
    currentFilter.users,
    auditEventState.ids,
    selectedEntityId
  ]);
  return {
    auditEventState,
    setCurrentCursor,
    setCurrentCursorHasMore,
    setCurrentHasNew,
    setCurrentFilter,
    currentCursor,
    currentHasMore,
    currentHasNew,
    currentFilter,
    userOptions,
    selectedFieldId,
    selectedEntityId,
    setSelectedEntityField,
    nextPage,
    hasMore,
    isLoading,
    filteredAllEvents,
    resetFilters
  };
};
