import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { auth } from "../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../hooks/authStateChange.hooks";
import { usePartyHooks } from "../../hooks/party.hooks";
import { useQueryParamsHooks } from "../../hooks/queryParams.hooks";
import { partyApi } from "../../redux/v2/party";
import PartyStructure from "./PartyStructure";

const PartyPage = () => {
  const [getParty] = partyApi.useLazyGetQuery();

  /** getting partyId from url via path param */
  // const { partyId: paramsPartyId } = useParams();

  const params = useQueryParamsHooks();
  const paramsPartyId = params.get("party") || "";

  const { setSelectedParty } = usePartyHooks();

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (auth.currentUser && paramsPartyId) {
        getParty(paramsPartyId);
        setSelectedParty(paramsPartyId);

        //** clear url queryPara, if extracted partyId from query param */
        window.history.replaceState(null, "", window.location.pathname);
      }
    },
    deps: [paramsPartyId, params]
  });

  return (
    <Box h="100%" w="100%" overflow="hidden">
      <Tabs h="100%" w="100%" position="sticky" top="0">
        <TabList>
          <Tab>Parties Structure</Tab>
        </TabList>
        <TabPanels h="100%" w="100%" p="0">
          <TabPanel h="100%" w="100%" p="0">
            <PartyStructure />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default PartyPage;
