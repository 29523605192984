import { Property } from "../../../types";
import { removeEmpty } from "../common.utils";

export const buildPropertyName = (p: Property) => {
  const addressLineText = p?.Address?.AddressLineText ?? "";
  const addressUnitIdentifier = p?.Address?.AddressUnitIdentifier ?? "";
  const cityName = p?.Address?.CityName ?? "";
  const stateCode = p?.Address?.StateCode ?? "";
  const postalCode = p?.Address?.PostalCode ?? "";

  const parts = [
    addressLineText,
    addressUnitIdentifier,
    cityName && `${cityName},`,
    stateCode,
    postalCode
  ].filter(removeEmpty);

  return parts ? parts.join(" ") : undefined;
};
